export const NOT_FOUND_TITLE = {
  sk: 'Stránka nenájdená',
};
export const NOT_FOUND_DESCRIPTION = {
  sk: 'Stránku, ktorú hľadáte sme nenašli!',
};
export const NOT_FOUND_GOTO = {
  sk: 'Stránka môže byť odstránená alebo nedostupná. Pokračujte prosím na <a href="/">hlavnú stránku</a>.',
};
export const FORM_ARE_REALLY_WANT_TO_LEAVE = {
  sk: 'Vo formulári máte neuložené zmeny. Naozaj chcete odísť?',
};
export const AUTH_LOGIN_WELCOME = {
  sk: 'Vitajte v ASAP systéme!',
};
export const AUTH_LOGIN_HEADING = {
  sk: 'Prihlásenie',
};
export const AUTH_LOGIN_FORGOTTEN_PASSWORD = {
  sk: 'Zabudli ste heslo?',
};
export const AUTH_LOGIN_DESCRIPTION = {
  sk: 'Pre vstup do systému sa prihláste',
};
export const AUTH_LOGIN_USERNAME = {
  sk: 'Prihlasovacie meno',
};
export const AUTH_LOGIN_USERNAME_IS_REQUIRED = {
  sk: 'Prihlasovacie meno je povinná položka',
};
export const AUTH_LOGIN_PASSWORD = {
  sk: 'Heslo',
};
export const AUTH_LOGIN_PASSWORD_IS_REQUIRED = {
  sk: 'Heslo je povinná položka',
};
export const AUTH_LOGIN_SIGN_IN = {
  sk: 'Prihlásiť',
};
export const AUTH_LOGIN_SIGNING_IN = {
  sk: 'Prihlasovanie',
};
export const SERVER_ERROR_TOAST_DESCRIPTION = {
  sk: 'Počas spracovania nastala chyba na serveri.<br>Nahláste prosím túto chybu administrátorom.<br><br>Ďakujeme za trpezlivosť a pochopenie :)',
};
export const SERVER_ERROR_TOAST_TITLE = {
  sk: 'Chyba',
};
export const VALIDATION_TOAST_TITLE = {
  sk: 'Chyba',
};
export const VALIDATION_TOAST_DESCRIPTION = {
  sk: 'Vo formulári sa nachádzajú chyby!',
};
export const COPYRIGHT = {
  sk: '&copy; {{ year }}. Všetky práva vyhradené.',
};
export const CREATED_BY = {
  sk: 'Vytvorili v',
};
export const FORGOTTEN_PASSWORD_EMAIL_HAS_BEEN_SENT = {
  sk: 'Na vašu e-mailovú adresu boli odoslané inštrukcie ako resetovať heslo.',
};
export const FORGOTTEN_PASSWORD_EMAIL_HAS_BEEN_SENT_TITLE = {
  sk: 'E-mail bol odoslaný',
};
export const FORGOTTEN_PASSWORD_EMAIL_IS_REQUIRED = {
  sk: 'E-mail je povinný',
};
export const FORGOTTEN_PASSWORD_HEADING = {
  sk: 'Zabudnuté heslo',
};
export const FORGOTTEN_PASSWORD_DESCRIPTION = {
  sk: 'Zadajte svoju emailovú adresu na resetovanie hesla.',
};
export const FORGOTTEN_PASSWORD_BACK_TO_SIGN_IN = {
  sk: 'Späť na prihlásenie',
};
export const FORGOTTEN_PASSWORD_RESET = {
  sk: 'Resetovať',
};
export const FORGOTTEN_PASSWORD_RESETING = {
  sk: 'Resetuje sa',
};
export const FORGOTTEN_PASSWORD_EMAIL = {
  sk: 'E-mailová adresa',
};
export const CSRF_ERROR_TOAST_TITLE = {
  sk: 'Neplatný token',
};
export const CSRF_ERROR_TOAST_DESCRIPTION = {
  sk: 'Platnosť vašeho tokenu vypršala. Prosím obnovte stránku a skúste operáciu znova.',
};
export const NOT_AUTHORIZED_TOAST_TITLE = {
  sk: 'Neautorizovaná akcia',
};
export const NOT_AUTHORIZED_TOAST_DESCRIPTION = {
  sk: 'Na túto akciu nemáte oprávnenie.',
};
export const CHANGE_PASSWORD_BACK_TO_SIGN_IN = {
  sk: 'Späť na prihlásenie',
};
export const CHANGE_PASSWORD_HEADING = {
  sk: 'Zmena hesla',
};
export const CHANGE_PASSWORD_DESCRIPTION = {
  sk: 'Nastavte si svoje nové heslo.',
};
export const CHANGE_PASSWORD_TOKEN_INVALID = {
  sk: 'Platnosť vášho kódu vypršala. Skúste to prosím znova.',
};
export const CHANGE_PASSWORD_NEW_PASSWORD = {
  sk: 'Nové heslo',
};
export const CHANGE_PASSWORD_NEW_PASSWORD_CHECK = {
  sk: 'Kontrola hesla',
};
export const CHANGE_PASSWORD_SUBMIT = {
  sk: 'Zmeniť heslo',
};
export const CHANGE_PASSWORD_SUBMITTING = {
  sk: 'Ukladanie',
};
export const CHANGE_PASSWORD_PASSWORD_IS_REQUIRED = {
  sk: 'Heslo je povinné',
};
export const CHANGE_PASSWORD_PASSWORD_TOO_SHORT = {
  sk: 'Heslo je príliš krátke. Minimálna dĺžka je {{ minLength }} znakov',
};
export const CHANGE_PASSWORD_PASSWORDS_DO_NOT_MATCH = {
  sk: 'Heslá sa nezhodujú',
};
export const CHANGE_PASSWORD_CHANGED_TITLE = {
  sk: 'Heslo zmenené',
};
export const LOAD_ERROR_MESSAGE = {
  sk: 'Nepodarilo sa načítať obsah kvôli chybe.',
};
export const LOAD_ERROR_RETRY = {
  sk: 'Skúsiť znova',
};
export const NOT_FOUND_ERROR_TITLE = {
  sk: 'Stránka nenájdená',
};
export const APP_NAME = {
  sk: 'ASAP',
};
export const LOGGED_LAYOUT_NAVIGATION = {
  sk: 'Navigácia',
};
export const LOGGED_LAYOUT_TOGGLE_NAVIGATION = {
  sk: 'Prepnúť navigáciu',
};
export const LOGGED_LAYOUT_TOGGLE_MENUBAR = {
  sk: 'Prepnúť menu',
};
export const LOGGED_LAYOUT_BREADCRUMBS = {
  sk: 'Nachádzate sa tu',
};
export const LOGGED_LAYOUT_MENU_DASHBOARD = {
  sk: 'Dashboard',
};
export const LOGGED_LAYOUT_MENU_ADMINISTRATION = {
  sk: 'Administrácia',
};
export const LOGGED_LAYOUT_MENU_ADMINISTRATION_COMPANY = {
  sk: 'Firemné údaje',
};
export const LOGGED_LAYOUT_MENU_ADMINISTRATION_OFFER_SENT_SETTINGS = {
  sk: 'Ponuky vystavené',
};
export const LOGGED_LAYOUT_MENU_ADMINISTRATION_USERS = {
  sk: 'Používatelia',
};
export const DASHBOARD_TITLE = {
  sk: 'Dashboard',
};
export const HEADER_MENU_ACCOUNT = {
  sk: 'Nastavenia',
};
export const HEADER_MENU_LOGOUT = {
  sk: 'Odhlásiť',
};
export const ADMIN_USERS_TITLE = {
  sk: 'Administrácia používateľov',
};
export const ADMIN_USERS_BREADCRUMB_TITLE = {
  sk: 'Používatelia',
};
export const ADMIN_USERS_PANEL_DESCRIPTION = {
  sk: 'Tu môžete spravovať prístup používateľov do systému.',
};
export const ADMIN_USERS_NEW_USER = {
  sk: 'Nový používateľ',
};
export const ADMIN_USERS_COLUMN_ID = {
  sk: 'ID',
};
export const ADMIN_USERS_COLUMN_USERNAME = {
  sk: 'Prihlasovacie meno',
};
export const ADMIN_USERS_COLUMN_FIRST_NAME = {
  sk: 'Meno',
};
export const ADMIN_USERS_COLUMN_LAST_NAME = {
  sk: 'Priezvisko',
};
export const ADMIN_USERS_COLUMN_EMAIL = {
  sk: 'E-mail',
};
export const ADMIN_USERS_COLUMN_ROLE = {
  sk: 'Rola',
};
export const ADMIN_USERS_COLUMN_ACTIVE = {
  sk: 'Môže sa prihlásiť?',
};
export const REACT_TABLE_PREVIOUS = {
  sk: 'Predošlá',
};
export const REACT_TABLE_NEXT = {
  sk: 'Ďaľšia',
};
export const REACT_TABLE_LOADING = {
  sk: 'Načítavanie...',
};
export const REACT_TABLE_NO_DATA = {
  sk: 'Nenašli sa žiadne záznamy',
};
export const REACT_TABLE_PAGE = {
  sk: 'Strana',
};
export const REACT_TABLE_OF = {
  sk: 'z',
};
export const REACT_TABLE_ROWS = {
  sk: 'záznamov',
};
export const COLUMN_ACTIONS = {
  sk: 'Akcie',
};
export const TOOLTIP_EDIT = {
  sk: 'Upraviť',
};
export const TOOLTIP_SHOW = {
  sk: 'Detail',
};
export const TOOLTIP_DEACTIVATE = {
  sk: 'Deaktivovať prihlásenie',
};
export const TOOLTIP_ACTIVATE = {
  sk: 'Aktivovať prihlásenie',
};
export const TOOLTIP_DELETE = {
  sk: 'Zmazať',
};
export const TOOLTIP_DOWNLOAD_PDF = {
  sk: 'Stiahnúť PDF',
};
export const USER_VALIDATION_USERNAME_IS_REQUIRED = {
  sk: 'Prihlasovacie meno je povinné',
};
export const USER_VALIDATION_EMAIL_IS_REQUIRED = {
  sk: 'E-mailová adresa je povinná',
};
export const USER_VALIDATION_EMAIL_IS_INVALID = {
  sk: 'E-mailová adresa nie je platná',
};
export const USER_VALIDATION_ROLE_IS_REQUIRED = {
  sk: 'Užívateľská rola je povinná',
};
export const USER_VALIDATION_PASSWORD_IS_REQUIRED = CHANGE_PASSWORD_PASSWORD_IS_REQUIRED;
export const USER_VALIDATION_PASSWORD_TOO_SHORT = CHANGE_PASSWORD_PASSWORD_TOO_SHORT;
export const USER_VALIDATION_PASSWORDS_DO_NOT_MATCH = CHANGE_PASSWORD_PASSWORDS_DO_NOT_MATCH;
export const USER_FORM_USERNAME = {
  sk: 'Prihlasovacie meno',
};
export const USER_FORM_FIRST_NAME = {
  sk: 'Meno',
};
export const USER_FORM_LAST_NAME = {
  sk: 'Priezvisko',
};
export const USER_FORM_EMAIL = {
  sk: 'E-mail',
};
export const USER_FORM_ROLE = {
  sk: 'Rola',
};
export const USER_FORM_PASSWORD = {
  sk: 'Heslo',
};
export const USER_FORM_PASSWORD_CHECK = {
  sk: 'Heslo (kontrola)',
};
export const USER_FORM_IS_ACTIVE = {
  sk: 'Môže sa prihlásiť',
};
export const ADMIN_NEW_USER_TITLE = {
  sk: 'Vytvorenie nového používateľa',
};
export const ADMIN_NEW_USER_BREADCRUMB_TITLE = {
  sk: 'Vytvorenie',
};
export const ADMIN_NEW_USER_CREATED_MESSAGE = {
  sk: 'Úspešne ste vytvorili nového používateľa "{{ username }}"',
};
export const ADMIN_NEW_USER_CREATED_TITLE = {
  sk: 'Používateľ vytvorený',
};
export const ADMIN_EDIT_USER_TITLE = {
  sk: 'Úprava používateľa',
};
export const ADMIN_EDIT_USER_BREADCRUMB_TITLE = {
  sk: 'Úprava',
};
export const ADMIN_EDIT_USER_UPDATED_MESSAGE = {
  sk: 'Úspešne ste upravili používateľa "{{ username }}"',
};
export const ADMIN_EDIT_USER_UPDATED_TITLE = {
  sk: 'Používateľ upravený',
};
export const ADMIN_EDIT_USERS_BACK_TO_USERS = {
  sk: 'Späť na používateľov',
};
export const REACT_SELECT = {
  sk: {
    addLabelText: 'Pridať "{label}"?',
    backspaceToRemoveMessage: 'Stlačte backspace pre odstránenie {label}',
    clearAllText: 'Vyčistiť všetko',
    clearValueText: 'Odstrániť',
    loadingPlaceholder: 'Načítava sa...',
    noResultsText: 'Nenašli sa žiadne výsledky',
    placeholder: 'Vyberte...',
    promptTextCreator: 'Vytvoriť položku "{{value}}"',
    searchPromptText: 'Začnite písať',
  },
};
export const BUTTON_CREATE = {
  sk: 'Vytvoriť',
};
export const BUTTON_CREATING = {
  sk: 'Vytváranie',
};
export const BUTTON_UPDATE = {
  sk: 'Upraviť',
};
export const BUTTON_UPDATING = {
  sk: 'Upravovanie',
};
export const BUTTON_SAVE = {
  sk: 'Uložiť',
};
export const BUTTON_SAVING = {
  sk: 'Ukladanie',
};
export const BUTTON_BACK = {
  sk: 'Späť',
};
export const COUNTRIES_FAVORITE = {
  sk: 'Obľúbené',
};
export const COUNTRIES_OTHERS = {
  sk: 'Ostatné',
};
export const YES = {
  sk: 'áno',
};
export const NO = {
  sk: 'nie',
};
export const SERVER_ERROR_TITLE = {
  sk: 'Neočakávaná chyba',
};
export const SERVER_ERROR_APOLOGIES = {
  sk: 'Ospravedlňujeme sa za výpadok',
};
export const SERVER_ERROR_MESSAGE = {
  sk: 'V aplikácii nastala chyba, s ktorou sme nerátali.',
};
export const SERVER_ERROR_MESSAGE_WITH_REPORT = {
  sk: 'V aplikácii nastala chyba, s ktorou sme nerátali. Ak nám chcete pomôcť, čo najrýchlejšie problém odstrániť, kliknite na tlačítko nižšie a vyplňte dodatočné informácie o tom, ako ste na chybu narazili.',
};
export const SERVER_ERROR_REPORT = {
  sk: 'Nahlásiť detail chyby',
};
export const SERVER_ERROR_SENTRY_ERROR_FORM_ENTRY = {
  sk: 'Niektoré položky sú neplatné. Prosím skontrolujte ich.',
};
export const SERVER_ERROR_SENTRY_ERROR_GENERIC = {
  sk: 'Nastala neočdakávaná chyba pri odosielaní tvojho reportu. Skúste to prosím znova.',
};
export const SERVER_ERROR_SENTRY_LABEL_CLOSE = {
  sk: 'Zavrieť',
};
export const SERVER_ERROR_SENTRY_LABEL_COMMENTS = {
  sk: 'Čo presne sa stalo? Čo ste chceli urobiť?',
};
export const SERVER_ERROR_SENTRY_LABEL_EMAIL = {
  sk: 'E-mail',
};
export const SERVER_ERROR_SENTRY_LABEL_NAME = {
  sk: 'Vaše meno',
};
export const SERVER_ERROR_SENTRY_LABEL_SUBMIT = {
  sk: 'Odoslať',
};
export const SERVER_ERROR_SENTRY_SUBTITLE = {
  sk: 'ASAP bol informovaný',
};
export const SERVER_ERROR_SENTRY_SUBTITLE2 = {
  sk: 'Ak nám chcete pomôcť, napíšte nám, čo sa stalo.',
};
export const SERVER_ERROR_SENTRY_SUCCESS_MESSAGE = {
  sk: 'Váš report bol úspešne odoslaný. Ďakujeme',
};
export const SERVER_ERROR_SENTRY_TITLE = {
  sk: 'Vyzerá to, že máme nejaký technický problém.',
};
export const DATE_FORMAT = {
  sk: 'dd.MM.yyyy',
};
export const TIME_FORMAT = {
  sk: 'HH:mm',
};
export const DATETIME_FORMAT = {
  sk: 'dd.MM.yyyy HH:mm',
};
export const ORDERS_RECEIVED_COLUMN_NUMBER = {
  sk: 'Číslo dokladu',
};
export const ORDERS_RECEIVED_COLUMN_COMPANY_NAME = {
  sk: 'Odberateľ - firma',
};
export const ORDERS_RECEIVED_COLUMN_CONTACT_NAME = {
  sk: 'Odberateľ - osoba',
};
export const ORDERS_RECEIVED_COLUMN_DESCRIPTION = {
  sk: 'Popis',
};
export const ORDERS_RECEIVED_COLUMN_CREATED_AT = {
  sk: 'Dátum prijatia',
};
export const ORDERS_RECEIVED_COLUMN_ISSUED_AT = {
  sk: 'Dátum vystavenia',
};
export const ORDERS_RECEIVED_COLUMN_EXPEDITION_AT = {
  sk: 'Dátum expedície',
};
export const ORDERS_RECEIVED_COLUMN_ASSEMBLY_AT = {
  sk: 'Dátum montáže',
};
export const ORDERS_RECEIVED_COLUMN_STATUS = {
  sk: 'Stav',
};
export const ORDERS_RECEIVED_TITLE = {
  sk: 'Objednávky prijaté',
};
export const LOGGED_LAYOUT_MENU_ORDERS = {
  sk: 'Objednávky',
};
export const LOGGED_LAYOUT_MENU_ORDERS_RECEIVED = {
  sk: 'Objednávky prijaté',
};
export const ORDER_RECEIVED_STATUS_NEW = {
  sk: 'Nová',
};
export const ORDER_RECEIVED_STATUS_OPEN = {
  sk: 'Otvorená',
};
export const ORDER_RECEIVED_STATUS_PROCESSING = {
  sk: 'Prebieha',
};
export const ORDER_RECEIVED_STATUS_PAUSED = {
  sk: 'Pozastavená',
};
export const ORDER_RECEIVED_STATUS_CANCELED = {
  sk: 'Stornovaná',
};
export const ORDER_RECEIVED_STATUS_DONE = {
  sk: 'Hotová',
};
export const ORDER_RECEIVED_STATUS_CLOSED = {
  sk: 'Uzavrená',
};
export const ORDER_RECEIVED_TITLE = {
  sk: '{{ client }} - {{ name }}',
};
export const ORDER_RECEIVED_BREADCRUMB_TITLE = {
  sk: 'Detail ponuky',
};
export const ORDER_RECEIVED_ABOUT_CUSTOMER = {
  sk: 'Údaje o zákazníkovi',
};
export const ORDER_RECEIVED_ABOUT_DELIVERY = {
  sk: 'Doprava a platba',
};
export const ORDER_RECEIVED_INVOICE_ADDRESS = {
  sk: 'Fakturačná adresa',
};
export const ORDER_RECEIVED_INVOICE_ADDRESS_PERSON = {
  sk: 'Osoba',
};
export const ORDER_RECEIVED_CRN = {
  sk: 'IČO',
};
export const ORDER_RECEIVED_LOCAL_VAT_ID = {
  sk: 'DIČ',
};
export const ORDER_RECEIVED_VAT_ID = {
  sk: 'IČ DPH',
};
export const ORDER_RECEIVED_DELIVERY_ADDRESS = {
  sk: 'Dodacia adresa',
};
export const ORDER_RECEIVED_PAYMENT_TYPE = {
  sk: 'Spôsob platby',
};
export const ORDER_RECEIVED_DELIVERY_TYPE = {
  sk: 'Spôsob dopravy',
};
export const ORDER_RECEIVED_PACKAGE_NUMBER = {
  sk: 'Číslo zásielky',
};
export const ORDER_RECEIVED_EXPEDITION_NOTE = {
  sk: 'Poznámka pre expedíciu',
};
export const ORDER_RECEIVED_EXPEDITION_NOTE_PLACEHOLDER = {
  sk: 'Sem môžete napísať textovú poznámku pre expedíciu ...',
};
export const ORDER_RECEIVED_PLANNING = {
  sk: 'Plánovanie',
};
export const ORDER_RECEIVED_PLANNING_RECEIVED = {
  sk: 'Prijaté',
};
export const ORDER_RECEIVED_PLANNING_GRAPHICS_APPROVED = {
  sk: 'Schválenie graf. dát',
};
export const ORDER_RECEIVED_PLANNING_EXPEDITION = {
  sk: 'Dátum expedície',
};
export const ORDER_RECEIVED_PLANNING_ASSEMBLY_PLAN = {
  sk: 'Dátum plánovanej montáže',
};
export const ORDER_RECEIVED_PLANNING_ASSEMBLY = {
  sk: 'Dátum montáže',
};
export const ORDER_RECEIVED_PRODUCTS = {
  sk: 'Produkty',
};
export const ORDER_RECEIVED_ITEMS_WITHOUT_PRODUCT = {
  sk: 'Nezaradené položky',
};
export const ORDER_RECEIVED_ITEMS_WITHOUT_PRODUCT_DESCRIPTION = {
  sk: 'Tieto položky sa nachádzajú v Money S4, ale neboli priradené do žiadného produktu v ASAPe.',
};
export const ORDER_RECEIVED_REORDER_ITEMS = {
  sk: 'Preskupiť položky',
};
export const DATEPICKER_TIME_CAPTION = {
  sk: 'Čas',
};
export const COPIED_TO_CLIPBOARD_ERROR_TITLE = {
  sk: 'Váš prehliadač nepodporuje kopírovanie do clipboardu',
};
export const COPIED_TO_CLIPBOARD_TITLE = {
  sk: 'Skopírované do clipboardu',
};
export const REACT_TABLE_VISIBILITY_CLOSE = {
  sk: 'Zavrieť',
};
export const REACT_TABLE_VISIBILITY_TOGGLE_ALL = {
  sk: 'Prepnúť všetky',
};
export const REACT_TABLE_VISIBILITY_HEADER = {
  sk: 'Viditeľnosť stĺpcov',
};
export const PASSWORD_IS_NOT_STRONG = {
  sk: 'Heslo nie je dostatočne silné. Musí mať aspoň 8 znakov, 1 malé písmeno, 1 veľké písmeno, 1 číslo a 1 symbol.',
};

export const DATE_PICKERS_CANCEL_BUTTON_LABEL = {
  sk: 'Zrušiť',
} as const;

export const DATE_PICKERS_CLEAR_BUTTON_LABEL = {
  sk: 'Zmazať',
} as const;

export const DATE_PICKERS_NEXT_MONTH = {
  sk: 'Ďalší mesiac',
} as const;

export const DATE_PICKERS_OK_BUTTON_LABEL = {
  sk: 'OK',
} as const;

export const DATE_PICKERS_OPEN_NEXT_VIEW = {
  sk: 'open next view',
} as const;

export const DATE_PICKERS_OPEN_PREVIOUS_VIEW = {
  sk: 'open previous view',
} as const;

export const DATE_PICKERS_PREVIOUS_MONTH = {
  sk: 'Predchádzajúci mesiac',
} as const;

export const DATE_PICKERS_TODAY_BUTTON_LABEL = {
  sk: 'Dnes',
} as const;

export const RESET_PASSWORD_BACK_TO_SIGN_IN = {
  sk: 'Späť na prihlásenie',
} as const;

export const RESET_PASSWORD_CHANGED_MESSAGE = {
  sk: 'Úspešne ste zmenili svoje heslo a boli ste prihlásený do systému',
} as const;

export const RESET_PASSWORD_DESCRIPTION = {
  sk: 'Nastavte si svoje nové heslo.',
} as const;

export const RESET_PASSWORD_NEW_PASSWORD = {
  sk: 'Nové heslo',
} as const;

export const RESET_PASSWORD_NEW_PASSWORD_CHECK = {
  sk: 'Heslo (kontrola)',
} as const;

export const RESET_PASSWORD_PASSWORD_INVALID = {
  sk: 'Vaše heslo je slabé. Malo by byť aspoň 8 znakov, obsahovať veľké a malé písmeno, číslicu a nesmie obsahovať medzeru.',
} as const;

export const RESET_PASSWORD_PASSWORD_IS_REQUIRED = {
  sk: 'Heslo je povinné',
} as const;

export const RESET_PASSWORD_PASSWORDS_DO_NOT_MATCH = {
  sk: 'Heslá sa nezhodujú',
} as const;

export const RESET_PASSWORD_SUBMIT = {
  sk: 'Zmeniť heslo',
} as const;

export const RESET_PASSWORD_TOKEN_INVALID = {
  sk: 'Platnosť vášho kódu vypršala. Skúste to prosím znova.',
} as const;

export const SEO_APP_LABEL = {
  sk: 'ASAP | ASAPRINT',
} as const;

export const NOT_AUTHORIZED_DESCRIPTION = {
  sk: 'Na zobrazenie tejto stránke nemáte dostatočné oprávnenia',
} as const;

export const NOT_AUTHORIZED_GOTO = {
  sk: '<0>Vrátiť sa späť</0>.',
} as const;

export const NOT_AUTHORIZED_TITLE = {
  sk: 'Stránka nedostupná',
} as const;

export const DROPZONE_UNKNOWN_ERROR = {
  sk: 'Neznáma chyba',
} as const;
